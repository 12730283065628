<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <UsersTable v-if="$route.name === 'projectSettingsUsers'" />
      <UsersCreate v-if="$route.name === 'projectSettingsUsersCreate'" />
      <UsersEdit
        v-if="$route.name === 'projectSettingsUsersAccessInformation'"
      />
    </div>
  </div>
</template>

<script>
import UsersEdit from "@/components/Settings/Users/Details.vue";
import UsersCreate from "@/components/Settings/Users/Create.vue";
import UsersTable from "@/components/Settings/Users/Table.vue";

export default {
  name: "ProjectsUsersIndex",
  components: {
    UsersEdit,
    UsersCreate,
    UsersTable
  }
};
</script>
