<template>
  <div>
    <Header
      :title="$t('config.configCreate').toString()"
      :show-back-button="true"
      :items="headerButtons"
      @back="$router.push({ name: 'projectSettingsConfig' })"
      @save="storeConfig"
    />
    <div class="card card-custom">
      <div class="card-body">
        <FormHelper
          ref="form"
          v-model="config"
          :form="form"
          :config="formConfig"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Config from "@/components/Settings/Config/config";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";

export default {
  name: "ProjectsSettingsConfigCreate",
  components: { Header, FormHelper },
  data() {
    return {
      headerButtons: [
        {
          type: "button",
          title: this.$t("general.save"),
          emit: "save"
        }
      ],
      formConfig: {
        snippetPrefix: "project",
        enableVariables: false
      },
      form: [
        {
          label: this.$t("config.name"),
          name: "name",
          type: "text",
          disableReturnType: true,
          default: "",
          validations: {
            required: true,
            minLength: 3
          }
        },
        {
          label: this.$t("config.label"),
          name: "label",
          type: "text",
          disableReturnType: true,
          default: "",
          validations: {
            required: true,
            minLength: 3
          }
        },
        {
          label: this.$t("config.type"),
          name: "type",
          type: "select",
          options: [
            {
              text: "json",
              value: "json"
            },
            {
              text: "text",
              value: "text"
            },
            {
              text: "number",
              value: "number"
            }
          ]
        },
        {
          label: this.$t("config.value"),
          name: "value",
          type: "json",
          returnJson: true,
          fields: [
            {
              name: "key"
            },
            {
              name: "value"
            }
          ],
          dependsOn: [
            {
              name: "type",
              values: ["json"]
            }
          ]
        },
        {
          label: this.$t("config.value"),
          name: "value",
          type: "text",
          dependsOn: [
            {
              name: "type",
              values: ["text"]
            }
          ]
        },
        {
          label: this.$t("config.value"),
          name: "value",
          type: "number",
          dependsOn: [
            {
              name: "type",
              values: ["number"]
            }
          ]
        }
      ],
      config: {
        name: "",
        label: "",
        type: "json",
        value: ""
      }
    };
  },
  watch: {
    "config.type": {
      handler: function () {
        this.config.value = null;
      }
    }
  },
  methods: {
    storeConfig() {
      addEventToLoadingQueue({ key: "storeConfig" });
      Config.store(this.config)
        .then(() => {
          removeEventFromLoadingQueue({
            key: "storeConfig",
            type: "success",
            prefix: "config",
            name: "configCreated"
          });

          this.$router.push({ name: "projectSettingsConfig" });
        })
        .catch(error => {
          this.$error(error);
        });
    }
  }
};
</script>
