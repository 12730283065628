<template>
  <div>
    <!--begin::Authentications-->
    <div class="row">
      <div class="col-sm-12">
        <AuthTable
          v-if="$route.name === 'projectSettingsAuth'"
          @create-authentication="createAuthentication"
        />
        <AuthCreate
          v-if="$route.name === 'projectSettingsAuthCreate'"
          :copy-from="copyFrom"
        />
        <AuthEdit v-if="$route.name === 'projectSettingsAuthDetails'" />
      </div>
    </div>
    <!--end::Authentications-->
  </div>
</template>

<script>
import AuthTable from "@/components/Settings/Auth/Table.vue";
import AuthCreate from "@/components/Settings/Auth/Create.vue";
import AuthEdit from "@/components/Settings/Auth/Edit.vue";

export default {
  name: "ProjectsSettingsAuthIndex",
  components: {
    AuthTable,
    AuthCreate,
    AuthEdit
  },
  data() {
    return {
      copyFrom: null
    };
  },
  mounted() {},
  methods: {
    createAuthentication(copyFrom) {
      this.copyFrom = copyFrom;
      this.$router.push({ name: "projectSettingsAuthCreate" });
    }
  }
};
</script>
