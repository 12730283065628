<template>
  <div>
    <Header
      :title="$t('queues.queueUpdate').toString()"
      :show-back-button="true"
      :items="headerButtons"
      @back="$router.push({ name: 'projectSettingsQueues' })"
      @save="updateQueue"
    />
    <div class="card card-custom">
      <div class="card-body">
        <FormHelper ref="form" v-model="queue" :form="form" :config="config" />
      </div>
    </div>
  </div>
</template>

<script>
import Queues from "@/components/Settings/Queues/queues";
import { LOAD_PROJECTS } from "@/core/services/store/switchArea.module";
import { mapGetters } from "vuex";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";

export default {
  name: "ProjectsSettingsQueuesEdit",
  components: { Header, FormHelper },
  data() {
    return {
      config: {
        snippetPrefix: "project",
        enableVariables: false
      },
      form: [
        {
          label: this.$t("queues.name"),
          name: "raw_name",
          type: "text",
          disableReturnType: true,
          default: "",
          validations: {
            required: true,
            minLength: 3
          }
        },
        {
          label: this.$t("workflowDesigner.projectChoose"),
          name: "projects",
          type: "multiselect",
          clearable: true,
          options: [],
          validations: {
            required: true
          }
        },
        {
          label: this.$t("queues.maxParallelJobs"),
          name: "max_parallel_jobs",
          type: "select",
          options: [],
          validations: {
            required: true
          }
        },
        {
          label: this.$t("general.active"),
          name: "active",
          type: "checkbox"
        }
      ],
      queue: {
        name: "",
        active: true,
        tries: 1,
        timeout: 1200,
        memory: 256,
        max_parallel_jobs: 2,
        projects: []
      },
      maxParallelJobs: []
    };
  },
  computed: {
    ...mapGetters(["projects", "selectedPresetVersion", "isDevPresetVersion"]),
    headerButtons: function () {
      if (!this.isDevPresetVersion) {
        return [];
      }
      return [
        {
          type: "button",
          title: this.$t("general.save"),
          emit: "save",
          disabledWhenLoading: true
        }
      ];
    }
  },
  watch: {
    projects: function () {
      this.getProjectOptions();
    },
    selectedPresetVersion: function () {
      this.loadQueue();
      this.getProjectOptions();
    }
  },
  mounted() {
    this.loadQueue();

    this.getProjectOptions();

    const formJobs = Object.values(this.form).find(
      element => element.name === "max_parallel_jobs"
    );
    formJobs.options = this.setMaxParallelJobs();
  },
  methods: {
    loadQueue() {
      addEventToLoadingQueue({ key: "loadQueue" });
      let queueId = this.$route.params.queueId;
      Queues.get(queueId)
        .then(response => {
          this.queue = response.data;
          this.queue.projects = this.queue.projects.map(project => project.id);
          removeEventFromLoadingQueue({ key: "loadQueue" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    updateQueue() {
      addEventToLoadingQueue({ key: "updateQueue" });
      let queue = this.queue;

      Queues.update(queue.id, queue)
        .then(response => {
          this.queue = response.data;
          removeEventFromLoadingQueue({
            key: "updateQueue",
            type: "success",
            prefix: "queues",
            name: "queueUpdated",
            data: { raw_name: this.queue.raw_name }
          });
          this.$store.dispatch(LOAD_PROJECTS);
          this.$router.push({ name: "projectSettingsQueues" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    getProjectOptions() {
      // Fills multiselect projects
      const formProjects = Object.values(this.form).find(
        element => element.name === "projects"
      );
      formProjects.options = this.projects?.length
        ? this.projects.map(project => {
            return {
              label: project.name,
              value: project.id
            };
          })
        : [];
    },
    setMaxParallelJobs() {
      let maxParallelJobs = [];
      for (let i = 0; i <= 12; i++) {
        let tmpObj = {
          text: i,
          value: i
        };
        maxParallelJobs.push(tmpObj);
      }
      return maxParallelJobs;
    }
  }
};
</script>
