<template>
  <div>
    <Header
      :items="headerButtons"
      :title="$t('config.authentication').toString()"
      @create-authentication="
        $router.push({ name: 'projectSettingsAuthCreate' })
      "
    />
    <TableHelper
      ref="table"
      :actions="actions"
      :enable-filter="false"
      :fields="fields"
      :items="authentications"
      :meta="meta"
      loading-key="loadSettingsAuthentications"
      @delete="deleteAuthentication"
      @edit="editAuthentication"
      @reload-data="loadAuthentications"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import AuthValues from "@/components/Settings/Auth/authValues";
import { Confirmation } from "@/core/plugins/swal";
import i18n from "@/core/plugins/vue-i18n";

export default {
  name: "ProjectsSettingsAuthTable",
  components: {
    Header,
    TableHelper
  },
  data() {
    return {
      headerButtons: [
        {
          type: "button",
          title: this.$t("config.configCreate"),
          icon: this.$store.getters["config/icons"].add,
          emit: "create-authentication"
        }
      ],
      actions: [
        {
          key: "edit",
          icon: this.$store.getters["config/icons"].edit,
          emit: "edit",
          tooltip: this.$t("general.edit")
        },
        {
          key: "delete",
          icon: this.$store.getters["config/icons"].delete,
          emit: "delete",
          tooltip: this.$t("general.delete")
        }
      ],
      fields: [
        {
          key: "name",
          label: this.$t("config.name"),
          sortable: true,
          thStyle: { width: "200px" }
        },
        {
          key: "label",
          label: this.$t("config.label"),
          sortable: true,
          thStyle: { width: "200px" }
        },
        {
          key: "value",
          label: this.$t("config.value"),
          sortable: true,
          thStyle: { width: "500px" }
        },
        {
          key: "created_at",
          formatter: "formatDate",
          label: this.$t("table.createdAt"),
          sortable: true,
          thStyle: { "min-width": "180px" }
        }
      ],
      authentications: [],
      searchTitle: "",
      // Meta info
      meta: {},
      selectedAuthenticationValue: null
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams"])
  },
  mounted() {
    this.loadAuthentications();
  },
  methods: {
    loadAuthentications(payload = {}) {
      addEventToLoadingQueue({ key: "loadSettingsAuthentications" });
      let params = { ...this.requestParams(), ...payload };
      AuthValues.getAll(params)
        .then(response => {
          this.authentications = response.data;
          this.meta = response.meta;
          removeEventFromLoadingQueue({ key: "loadSettingsAuthentications" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    editAuthentication(item) {
      this.$router.push({
        name: "projectSettingsAuthDetails",
        params: { authId: item.item.id }
      });
    },
    deleteAuthentication(item) {
      Confirmation({
        title: i18n.t("config.configDeleteTitle"),
        text: i18n.t("config.configDeleteMessage", { config: item.item.name }),
        icon: "warning"
      }).then(result => {
        if (result.isConfirmed) {
          addEventToLoadingQueue({ key: "deleteAuthentication" });

          AuthValues.delete(item.item.id)
            .then(() => {
              removeEventFromLoadingQueue({
                key: "deleteAuthentication",
                type: "success",
                prefix: "authentication",
                name: "authenticationDeleted"
              });

              this.loadAuthentications();
            })
            .catch(error => {
              this.$error(error);
            });
        }
      });
    }
  }
};
</script>
