<template>
  <div>
    <Table v-if="$route.name === 'projectSettingsWorkflowToken'" />
    <Edit v-else />
  </div>
</template>

<script>
import Table from "@/components/Settings/FlowTokens/Table.vue";
import Edit from "@/components/Settings/FlowTokens/Edit.vue";

export default {
  name: "ProjectsSettingsWorkflowTokenIndex",
  components: {
    Table,
    Edit
  },
  data() {
    return {
      clientSelected: false,
      isBusy: false
    };
  },
  watch: {},
  mounted() {},
  methods: {}
};
</script>
