<template>
  <div>
    <Header
      :title="$t('presets.presetEdit').toString()"
      :show-back-button="true"
      :items="headerButtons"
      @back="$router.push({ name: 'projectSettingsPresets' })"
      @save="updatePreset"
    />
    <div class="card card-custom">
      <div class="card-body">
        <FormHelper
          ref="form"
          v-model="preset"
          :form="form"
          :preset="formPreset"
          @change="onChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import Presets from "@/components/Settings/Presets/presets";
import { mapGetters } from "vuex";

export default {
  name: "ProjectsSettingsPresetEdit",
  components: { Header, FormHelper },
  data() {
    return {
      formPreset: {
        snippetPrefix: "preset",
        enableVariables: false
      },
      form: [
        {
          label: this.$t("presets.name"),
          name: "name",
          type: "text",
          disableReturnType: true,
          default: "",
          validations: {
            required: true,
            minLength: 3
          }
        },
        {
          label: this.$t("presets.label"),
          name: "label",
          type: "text",
          disableReturnType: true,
          default: "",
          validations: {
            required: true,
            minLength: 3
          }
        },
        {
          label: this.$t("presets.type"),
          name: "type",
          type: "select",
          options: [
            {
              text: "json",
              value: "json"
            },
            {
              text: "text",
              value: "text"
            },
            {
              text: "number",
              value: "number"
            }
          ]
        },
        {
          label: this.$t("presets.value"),
          name: "value",
          type: "json",
          returnJson: true,
          fields: [
            {
              name: "key"
            },
            {
              name: "value"
            }
          ],
          dependsOn: [
            {
              name: "type",
              values: ["json"]
            }
          ]
        },
        {
          label: this.$t("presets.value"),
          name: "value",
          type: "text",
          dependsOn: [
            {
              name: "type",
              values: ["text"]
            }
          ]
        },
        {
          label: this.$t("presets.value"),
          name: "value",
          type: "number",
          dependsOn: [
            {
              name: "type",
              values: ["number"]
            }
          ]
        }
      ],
      preset: {
        name: "",
        label: "",
        type: "json",
        value: [],
        namespace: ""
      },
      presetId: null
    };
  },
  computed: {
    ...mapGetters([
      "selectedProject",
      "selectedPresetVersion",
      "isDevPresetVersion"
    ]),
    headerButtons: function () {
      if (!this.isDevPresetVersion) {
        return [];
      }
      return [
        {
          type: "button",
          title: this.$t("general.save"),
          emit: "save",
          disabledWhenLoading: true
        }
      ];
    }
  },
  watch: {
    selectedPresetVersion: function () {
      this.loadPreset();
    }
  },
  mounted() {
    this.presetId = this.$route.params.presetId;
    this.loadPreset();
  },
  methods: {
    onChange(item) {
      if (item.name !== "type") {
        return;
      }
      this.preset.value = null;
    },
    loadPreset() {
      addEventToLoadingQueue({ key: "loadPreset" });
      Presets.get(this.presetId)
        .then(response => {
          this.preset = response.data;
          removeEventFromLoadingQueue({ key: "loadPreset" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    updatePreset() {
      addEventToLoadingQueue({ key: "updatePreset" });

      // Set additional preset values
      let preset = this.preset;
      preset.project_id = this.selectedProject.id;
      preset.namespace = preset.label;

      Presets.update(preset.id, preset)
        .then(() => {
          removeEventFromLoadingQueue({
            key: "updatePreset",
            type: "success",
            prefix: "presets",
            name: "presetCreated"
          });

          this.$router.push({ name: "projectSettingsPresets" });
        })
        .catch(error => {
          this.$error(error);
        });
    }
  }
};
</script>
