import ApiService from "@/core/services/api.service";
import { BaseService } from "@/components/Tools/baseService";

let url = process.env.VUE_APP_API_ADMIN;

class FlowTokens extends BaseService {
  getAll(
    params,
    fields = [
      "id",
      "ip_whitelist",
      "ip_whitelist_active",
      "label",
      "processes",
      "value"
    ]
  ) {
    let options = this.getParams([], {}, fields);

    return ApiService.get(url, "flow_tokens?" + options);
  }

  get(id) {
    return ApiService.get(url, "flow_tokens/" + id);
  }

  store(data) {
    return ApiService.post(url + "/flow_tokens", data);
  }

  update(id, data) {
    return ApiService.post(url + "/flow_tokens/" + id, data);
  }

  delete(id) {
    return ApiService.delete(url + "/flow_tokens/" + id);
  }
}

export default new FlowTokens();
