<template>
  <div>
    <Header
      :title="$t('users.usersManagement').toString()"
      :items="headerButtons"
      @create-user="$router.push({ name: 'projectSettingsUsersCreate' })"
    />
    <TableHelper
      ref="table"
      :items="users"
      :meta="meta"
      :fields="fields"
      :actions="actions"
      :enable-filter="false"
      loading-key="loadSettingsUsers"
      @reload-data="loadUsers"
      @edit="editUser"
      @delete="deleteUser"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Users from "@/components/Settings/Users/users";
import { mapGetters } from "vuex";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";

export default {
  name: "ProjectsSettingsUsersTable",
  components: { Header, TableHelper },
  data() {
    return {
      users: [],
      meta: {},
      searchTitle: "",
      fields: [
        {
          key: "name",
          label: this.$t("users.user"),
          type: "user",
          sortable: true
        },
        {
          key: "active",
          label: this.$t("table.status"),
          sortable: true,
          type: "activeBadge"
        },
        {
          key: "language.label",
          label: this.$t("table.language"),
          sortable: true
        }
      ]
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams"]),
    ...mapGetters(["selectedPresetVersion", "isDevPresetVersion"]),
    headerButtons: function () {
      if (!this.isDevPresetVersion) {
        return [];
      }
      return [
        {
          type: "button",
          title: this.$t("users.userCreate"),
          icon: "fa-light fa-plus",
          emit: "create-user"
        }
      ];
    },
    actions: function () {
      const actions = [
        {
          key: "edit",
          icon: "fal fa-pen",
          emit: "edit",
          tooltip: this.$t("general.edit")
        }
      ];
      if (this.isDevPresetVersion) {
        actions.push({
          key: "delete",
          icon: "fal fa-trash",
          emit: "delete",
          tooltip: this.$t("general.delete")
        });
      }
      return actions;
    }
  },
  watch: {
    selectedPresetVersion: function () {
      this.loadUsers();
    }
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    loadUsers(payload = {}) {
      addEventToLoadingQueue({ key: "loadSettingsUsers" });

      let params = { ...this.requestParams("users"), ...payload };
      Users.getAll(params)
        .then(response => {
          this.users = response.data;
          this.meta = response.meta;
          removeEventFromLoadingQueue({ key: "loadSettingsUsers" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    editUser(item) {
      this.$router.push({
        name: "projectSettingsUsersAccessInformation",
        params: { id: item.item.id }
      });
    },
    deleteUser(item) {
      Swal.fire({
        title: this.$t("admin.adminDeleteTitle"),
        text: this.$t("admin.adminDeleteMessage", { user: item.item.name }),
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: this.$t("general.delete"),
        confirmButtonColor: "var(--primary)",
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: this.$t("general.cancel")
      }).then(result => {
        if (result.isConfirmed) {
          addEventToLoadingQueue({ key: "deleteSettingsUsers" });
          Users.delete(item.item.id)
            .then(() => {
              removeEventFromLoadingQueue({
                key: "deleteSettingsUsers",
                type: "success",
                prefix: "users",
                name: "userDeleted"
              });
              this.loadUsers();
            })
            .catch(error => {
              this.$error(error);
            });
        }
      });
    }
  }
};
</script>
