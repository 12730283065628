<template>
  <div>
    <!--begin::Presets-->
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <PresetsTable v-if="$route.name === 'projectSettingsPresets'" />
        <PresetsCreate v-if="$route.name === 'projectSettingsPresetsCreate'" />
        <PresetsEdit v-if="$route.name === 'projectSettingsPresetsEdit'" />
      </div>
    </div>
    <!--end::Presets-->
  </div>
</template>

<script>
import PresetsCreate from "@/components/Settings/Presets/Create.vue";
import PresetsTable from "@/components/Settings/Presets/Table.vue";
import PresetsEdit from "@/components/Settings/Presets/Edit.vue";

export default {
  name: "projectSettingsPresetsIndex",
  components: { PresetsEdit, PresetsTable, PresetsCreate },
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
