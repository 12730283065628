<template>
  <div class="row">
    <div class="col">
      <ProjectsTable v-if="$route.name === 'projectSettingsProjects'" />
      <ProjectsCreate
        v-else-if="$route.name === 'projectSettingsProjectsCreate'"
      />
      <ProjectsDetails
        v-else-if="$route.name.includes('projectSettingsProjectsDetails')"
      />
    </div>
  </div>
</template>

<script>
import ProjectsTable from "@/components/Settings/Projects/Table.vue";
import ProjectsCreate from "@/components/Settings/Projects/Create.vue";
import ProjectsDetails from "@/components/Settings/Projects/Detail.vue";

export default {
  name: "ProjectsSettingsProjectsIndex",
  components: {
    ProjectsTable,
    ProjectsCreate,
    ProjectsDetails
  },
  data() {
    return {};
  },
  watch: {},
  mounted() {},
  methods: {}
};
</script>
