<template>
  <div>
    <div class="d-flex flex-row">
      <div
        id="kt_profile_aside"
        class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      >
        <div class="card card-custom">
          <div class="card-body pt-4">
            <div class="text-h5 mb-3">
              {{ $t("menu.projectSettings").toString() }}
            </div>
            <div
              class="navi navi-bold navi-hover navi-active navi-link-rounded"
            >
              <div v-for="(item, i) in computedTabNavigation" :key="i">
                <div class="navi-item mb-1">
                  <router-link
                    v-slot="{ href, isExactActive }"
                    :to="{ name: item.route }"
                    custom
                  >
                    <a
                      :href="href"
                      class="navi-link"
                      :class="{
                        active:
                          isExactActive || $route.name.includes(item.route)
                      }"
                    >
                      <span v-if="item.icon" class="navi-icon">
                        <v-icon>{{ item.icon }}</v-icon>
                      </span>
                      <span class="navi-text">{{ $t(item.label) }}</span>
                    </a>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-row-fluid ml-lg-4 client-details">
        <projects v-if="$route.name.includes('projectSettingsProjects')" />
        <queues v-else-if="$route.name.includes('projectSettingsQueues')" />
        <configurations
          v-else-if="$route.name.includes('projectSettingsConfig')"
        />
        <authentication
          v-else-if="$route.name.includes('projectSettingsAuth')"
        />
        <presets v-else-if="$route.name.includes('projectSettingsPresets')" />
        <workflow-token
          v-else-if="$route.name.includes('projectSettingsWorkflowToken')"
        />
        <usersManagement
          v-else-if="$route.name.includes('projectSettingsUsers')"
        />
        <loginSecurity
          v-else-if="$route.name.includes('projectSettingsLoginSecurity')"
        />
        <plugins v-else-if="$route.name.includes('projectSettingsPlugins')" />
      </div>
    </div>
  </div>
</template>

<script>
import projects from "@/components/Settings/Projects/Index.vue";
import queues from "@/components/Settings/Queues/Index.vue";
import configurations from "@/components/Settings/Config/Index.vue";
import presets from "@/components/Settings/Presets/Index.vue";
import workflowToken from "@/components/Settings/FlowTokens/Index.vue";
import usersManagement from "@/components/Settings/Users/Index.vue";
import loginSecurity from "@/components/Settings/LoginSecurity/Index.vue";
import { mapGetters } from "vuex";
import Authentication from "@/components/Settings/Auth/Index.vue";

export default {
  components: {
    Authentication,
    projects,
    queues,
    configurations,
    presets,
    workflowToken,
    usersManagement,
    loginSecurity
  },
  data() {
    return {
      tabNavigation: [
        {
          route: "projectSettingsProjects",
          label: "menu.projectSettingsProjects",
          icon: "fal fa-tasks"
        },
        {
          route: "projectSettingsQueues",
          label: "menu.projectSettingsQueues",
          icon: "fal fa-indent"
        },
        {
          route: "projectSettingsConfig",
          label: "menu.projectSettingsConfiguration",
          icon: "fal fa-file-alt"
        },
        {
          route: "projectSettingsAuth",
          label: "menu.projectSettingsAuthentication",
          icon: "fal fa-lock"
        },
        {
          route: "projectSettingsPresets",
          label: "menu.projectSettingsPresets",
          icon: "fal fa-file-spreadsheet"
        },
        {
          route: "projectSettingsWorkflowToken",
          label: "menu.projectSettingsWorkflowToken",
          icon: "fal fa-key"
        },
        {
          route: "projectSettingsUsers",
          label: "users.usersManagement",
          icon: "fal fa-users"
        },
        {
          route: "projectSettingsLoginSecurity",
          label: "settings.loginSecurity",
          icon: "fal fa-shield"
        }
      ],
      headerItems: []
    };
  },
  computed: {
    ...mapGetters(["selectedProject"]),
    ...mapGetters("instance", ["instance"]),
    computedTabNavigation() {
      let navigation = [...this.tabNavigation];
      if (!this.selectedProject.preset_id && !this.instance.isPresetInstance) {
        navigation = navigation.filter(
          item => item.route !== "projectSettingsPresets"
        );
      }
      return navigation;
    }
  },
  mounted() {
    if (this.$route.name === "projectSettings") {
      this.$router.push({ name: "projectSettingsProjects" });
    }
  }
};
</script>

<style lang="scss" scoped>
.navi {
  .navi-item {
    .navi-link {
      .navi-text {
        font-weight: 400;
      }
      .navi-icon {
        i {
          color: $color-connect-text;
        }
      }
      &.active {
        .navi-icon {
          i {
            color: $color-connect-primary;
          }
        }
      }
    }
    &:hover {
      .navi-icon {
        i {
          color: $color-connect-primary;
        }
      }
    }
  }
}
</style>
