<template>
  <div>
    <Header
      :title="$t('projects.title').toString()"
      :items="headerButtons"
      @create-project="$router.push({ name: 'projectSettingsProjectsCreate' })"
    />
    <TableHelper
      ref="table"
      :items="projects"
      :meta="meta"
      :fields="fields"
      :actions="actions"
      :enable-filter="false"
      default-order-by="name"
      loading-key="loadSettingsProjects"
      @reload-data="loadProjects"
      @edit="showDetails"
      @delete="deleteProject"
    />
  </div>
</template>

<script>
import Projects from "@/components/Settings/Projects/project";
import { mapGetters } from "vuex";
import { LOAD_PROJECTS } from "@/core/services/store/switchArea.module";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";

export default {
  name: "ProjectsSettingsProjectsTable",
  components: { Header, TableHelper },
  data() {
    return {
      projects: [],
      searchTitle: "",
      fields: [
        {
          key: "name",
          label: this.$t("table.project"),
          sortable: true
        },
        {
          key: "active",
          label: this.$t("table.active"),
          sortable: true,
          type: "activeBadge"
        }
      ],
      // Meta info
      meta: {}
    };
  },
  computed: {
    ...mapGetters("route", ["params"]),
    ...mapGetters(["selectedPresetVersion", "isDevPresetVersion"]),
    headerButtons: function () {
      if (!this.isDevPresetVersion) {
        return [];
      }
      return [
        {
          type: "button",
          title: this.$t("projects.projectCreate"),
          icon: "fa-light fa-plus",
          emit: "create-project"
        }
      ];
    },
    actions: function () {
      const actions = [
        {
          key: "edit",
          icon: "fal fa-pen",
          emit: "edit",
          tooltip: this.$t("general.edit")
        }
      ];
      if (this.isDevPresetVersion) {
        actions.push({
          key: "delete",
          icon: "fal fa-trash",
          emit: "delete",
          tooltip: this.$t("general.delete")
        });
      }
      return actions;
    }
  },
  watch: {
    selectedPresetVersion: function () {
      this.loadProjects();
    }
  },
  mounted() {
    this.loadProjects();
  },
  methods: {
    loadProjects(payload = {}) {
      addEventToLoadingQueue({ key: "loadSettingsProjects" });

      let params = { ...this.params(), ...payload };

      Projects.getAll(params)
        .then(response => {
          this.projects = response.data;
          this.meta = response.meta;
          removeEventFromLoadingQueue({ key: "loadSettingsProjects" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    showDetails(item) {
      this.$router.push({
        name: "projectSettingsProjectsDetails",
        params: { projectId: item.item.id }
      });
    },
    deleteProject(project) {
      this.$swal
        .fire({
          title: this.$t("projects.removeProject", { name: project.item.name }),
          icon: "warning",
          showConfirmButton: true,
          confirmButtonText: this.$t("general.remove"),
          confirmButtonColor: "var(--primary)",
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("general.cancel")
        })
        .then(swalResponse => {
          if (swalResponse.isConfirmed) {
            addEventToLoadingQueue({ key: "deleteProject" });
            Projects.delete(project.item.id)
              .then(() => {
                removeEventFromLoadingQueue({
                  key: "deleteProject",
                  type: "success",
                  prefix: "projects",
                  name: "deleted",
                  data: { name: project.item.name }
                });

                this.loadProjects();
                this.$store.dispatch(LOAD_PROJECTS);
              })
              .catch(error => {
                this.$error(error);
              });
          }
        });
    }
  }
};
</script>
