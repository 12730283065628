<template>
  <div>
    <Header
      :title="$t('config.configUpdate').toString()"
      :subtitle="subtitle"
      :show-back-button="true"
      :items="headerButtons"
      @back="$router.push({ name: 'projectSettingsConfig' })"
      @save="updateConfig"
      @showVersions="onShowVersions"
    />
    <div class="card card-custom">
      <div class="card-body">
        <FormHelper
          ref="form"
          v-model="config"
          :form="form"
          :config="formConfig"
        />
      </div>
    </div>

    <BackupModal
      v-if="config"
      v-model="showVersionsModal"
      :object="config"
      classname="ConfigValue"
    />
  </div>
</template>

<script>
import Config from "@/components/Settings/Config/config";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import BackupModal from "@/components/Backup/BackupModal.vue";
import { formatDate } from "@/components/Tools/helperFunctions";
import { mapGetters } from "vuex";

export default {
  name: "ProjectsSettingsConfigEdit",
  components: { BackupModal, Header, FormHelper },
  data() {
    return {
      formConfig: {
        snippetPrefix: "project",
        enableVariables: false
      },
      form: [
        {
          label: this.$t("config.name"),
          name: "name",
          type: "text",
          disableReturnType: true,
          default: "",
          validations: {
            required: true,
            minLength: 3
          }
        },
        {
          label: this.$t("config.label"),
          name: "label",
          type: "text",
          disableReturnType: true,
          default: "",
          validations: {
            required: true,
            minLength: 3
          }
        },
        {
          label: this.$t("config.type"),
          name: "type",
          type: "select",
          options: [
            {
              text: "json",
              value: "json"
            },
            {
              text: "text",
              value: "text"
            },
            {
              text: "number",
              value: "number"
            }
          ]
        },
        {
          label: this.$t("config.value"),
          name: "value",
          type: "json",
          returnJson: true,
          fields: [
            {
              name: "key"
            },
            {
              name: "value"
            }
          ],
          dependsOn: [
            {
              name: "type",
              values: ["json"]
            }
          ]
        },
        {
          label: this.$t("config.value"),
          name: "value",
          type: "text",
          dependsOn: [
            {
              name: "type",
              values: ["text"]
            }
          ]
        },
        {
          label: this.$t("config.value"),
          name: "value",
          type: "number",
          dependsOn: [
            {
              name: "type",
              values: ["number"]
            }
          ]
        }
      ],
      config: {},
      configId: null,
      showVersionsModal: false,
      loading: true
    };
  },
  computed: {
    ...mapGetters(["selectedPresetVersion", "isDevPresetVersion"]),
    subtitle: function () {
      if (!this.config) {
        return "";
      }

      let subtitleParts = [];
      if (this.config.updated_by_user?.full_name) {
        subtitleParts.push(this.config.updated_by_user?.full_name);
      }
      if (this.config.updated_at) {
        subtitleParts.push(formatDate(this.config.updated_at));
      }
      if (subtitleParts.length === 0) {
        return "";
      }

      return subtitleParts.join(", ");
    },
    headerButtons: function () {
      const buttons = [
        {
          type: "button",
          icon: this.$store.getters["config/icons"].version,
          tooltip: this.$t("general.showVersions"),
          class: "btn-outline-primary",
          emit: "showVersions",
          disabledWhenLoading: true
        }
      ];
      if (this.isDevPresetVersion) {
        buttons.push({
          type: "button",
          title: this.$t("general.save"),
          emit: "save",
          disabledWhenLoading: true
        });
      }
      return buttons;
    }
  },
  watch: {
    "config.type": {
      handler: function () {
        if (this.loading) return;
        this.config.value = null;
      }
    },
    selectedPresetVersion: function () {
      this.loadConfig();
    }
  },
  mounted() {
    this.configId = this.$route.params.configId;
    this.loadConfig();
  },
  methods: {
    loadConfig() {
      addEventToLoadingQueue({ key: "loadSettingConfig" });
      Config.get(this.configId)
        .then(response => {
          removeEventFromLoadingQueue({ key: "loadSettingConfig" });
          this.config = response.data;
        })
        .catch(error => {
          this.$error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateConfig() {
      addEventToLoadingQueue({ key: "updateSettingConfig" });
      Config.update(this.configId, this.config)
        .then(() => {
          removeEventFromLoadingQueue({
            key: "updateSettingConfig",
            type: "success",
            prefix: "config",
            name: "configUpdated"
          });
          this.$router.push({ name: "projectSettingsConfig" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    onShowVersions() {
      this.showVersionsModal = true;
    }
  }
};
</script>
