<template>
  <div>
    <Header
      :title="$t('config.configCreate').toString()"
      :show-back-button="true"
      :items="headerButtons"
      @back="$router.push({ name: 'projectSettingsAuth' })"
      @save="storeAuthentication"
    />
    <div class="card card-custom">
      <div class="card-body">
        <FormHelper
          ref="form"
          v-model="authentication"
          :form="form"
          :config="formConfig"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import AuthValues from "@/components/Settings/Auth/authValues";
import { mapGetters } from "vuex";

export default {
  name: "ProjectsSettingsAuthCreate",
  components: { Header, FormHelper },
  data() {
    return {
      headerButtons: [
        {
          type: "button",
          title: this.$t("general.save"),
          emit: "save"
        }
      ],
      authentication: null,
      formConfig: {
        snippetPrefix: "project",
        enableVariables: false
      },
      form: [
        {
          label: this.$t("config.name"),
          name: "name",
          type: "text",
          disableReturnType: true,
          default: "",
          validations: {
            required: true,
            minLength: 3
          }
        },
        {
          label: this.$t("config.label"),
          name: "label",
          type: "text",
          disableReturnType: true,
          default: "",
          validations: {
            required: true,
            minLength: 3
          }
        },
        {
          label: this.$t("config.value"),
          name: "value",
          type: "json",
          returnJson: true,
          fields: [
            {
              name: "key"
            },
            {
              name: "value"
            }
          ]
        }
      ],
      config: {
        name: "",
        label: "",
        type: "json",
        value: ""
      }
    };
  },
  computed: {
    ...mapGetters(["selectedProject"])
  },
  methods: {
    storeAuthentication() {
      addEventToLoadingQueue({ key: "storeAuth" });
      this.authentication.project_id = this.selectedProject.id;
      AuthValues.store(this.authentication)
        .then(() => {
          removeEventFromLoadingQueue({
            key: "storeAuth",
            type: "success",
            prefix: "authentication",
            name: "authenticationCreated"
          });

          this.$router.push({ name: "projectSettingsAuth" });
        })
        .catch(error => {
          this.$error(error);
        });
    }
  }
};
</script>
