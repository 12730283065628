import ApiService from "@/core/services/api.service";

let url = process.env.VUE_APP_API_ADMIN;

class Settings {
  get(id) {
    return ApiService.get(url, "settings/" + id);
  }

  getAll(params) {
    const options = "?page=" + params.page + "&perPage=" + params.size;
    return ApiService.get(url, "settings" + options);
  }

  getByName(name) {
    return ApiService.get(url, "settings/getByName/" + name);
  }

  store(data) {
    return ApiService.post(url + "/settings", data);
  }

  update(id, data) {
    return ApiService.put(url + "/settings/" + id, data);
  }

  updateMany(data) {
    return ApiService.post(url + "/settings/updateMany", { settings: data });
  }

  delete(id) {
    return ApiService.delete(url + "/settings/" + id);
  }
}

export default new Settings();
