<template>
  <div>
    <Header
      :title="$t('config.configUpdate').toString()"
      :subtitle="subtitle"
      :show-back-button="true"
      :items="headerButtons"
      @back="$router.push({ name: 'projectSettingsAuth' })"
      @save="updateAuthValues"
    />
    <div class="card card-custom">
      <div class="card-body">
        <FormHelper
          ref="form"
          v-model="authentication"
          :form="form"
          :config="formConfig"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { formatDate } from "@/components/Tools/helperFunctions";
import AuthValues from "@/components/Settings/Auth/authValues";

export default {
  name: "ProjectsSettingsAuthEdit",
  components: { Header, FormHelper },
  data() {
    return {
      headerButtons: [
        {
          type: "button",
          title: this.$t("general.save"),
          emit: "save",
          disabledWhenLoading: true
        }
      ],
      formConfig: {
        snippetPrefix: "project",
        enableVariables: false
      },
      form: [
        {
          label: this.$t("config.name"),
          name: "name",
          type: "text",
          disableReturnType: true,
          default: "",
          validations: {
            required: true,
            minLength: 3
          }
        },
        {
          label: this.$t("config.label"),
          name: "label",
          type: "text",
          disableReturnType: true,
          default: "",
          validations: {
            required: true,
            minLength: 3
          }
        },
        {
          label: this.$t("config.value"),
          name: "value",
          type: "json",
          returnJson: true,
          fields: [
            {
              name: "key"
            },
            {
              name: "value"
            }
          ]
        }
      ],
      authentication: {},
      authId: null,
      loading: true
    };
  },
  computed: {
    subtitle: function () {
      if (!this.authentication) {
        return "";
      }

      let subtitleParts = [];
      if (this.authentication.updated_by_user?.full_name) {
        subtitleParts.push(this.authentication.updated_by_user?.full_name);
      }
      if (this.authentication.updated_at) {
        subtitleParts.push(formatDate(this.authentication.updated_at));
      }
      if (subtitleParts.length === 0) {
        return "";
      }

      return subtitleParts.join(", ");
    }
  },
  mounted() {
    this.authId = this.$route.params.authId;
    this.loadAuthentication();
  },
  methods: {
    loadAuthentication() {
      addEventToLoadingQueue({ key: "loadSettingAuthenticaton" });
      AuthValues.get(this.authId)
        .then(response => {
          removeEventFromLoadingQueue({ key: "loadSettingAuthenticaton" });
          this.authentication = response.data;
        })
        .catch(error => {
          this.$error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateAuthValues() {
      addEventToLoadingQueue({ key: "updateSettingAuth" });
      AuthValues.update(this.authId, this.authentication)
        .then(() => {
          removeEventFromLoadingQueue({
            key: "updateSettingAuth",
            type: "success",
            prefix: "auth",
            name: "authUpdated"
          });
          this.$router.push({ name: "projectSettingsAuth" });
        })
        .catch(error => {
          this.$error(error);
        });
    }
  }
};
</script>
