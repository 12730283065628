<template>
  <div>
    <Header
      :title="$t('queues.queues').toString()"
      :items="headerButtons"
      @create-queue="$router.push({ name: 'projectSettingsQueuesCreate' })"
    />
    <TableHelper
      ref="table"
      :items="queues"
      :meta="meta"
      :fields="fields"
      :actions="actions"
      :filterable="filterable"
      :enable-filter="true"
      default-order-by="name"
      loading-key="loadSettingsQueues"
      @reload-data="loadQueues"
      @edit="showDetails"
      @delete="deleteQueue"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Queues from "@/components/Settings/Queues/queues";
import { mapGetters } from "vuex";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { useCurrentProjectFilter } from "@/composables/useCurrentProjectFilter";

export default {
  name: "ProjectsSettingsQueuesTable",
  components: {
    Header,
    TableHelper
  },
  data() {
    return {
      tempFilterable: {},
      queues: [],
      searchTitle: "",
      fields: [
        {
          key: "raw_name",
          label: this.$t("queues.name"),
          sortable: true
        },
        {
          key: "max_parallel_jobs",
          label: this.$t("queues.maxParallelJobs"),
          sortable: true
        },
        {
          key: "active",
          label: this.$t("general.status"),
          type: "activeBadge",
          sortable: true
        },
        {
          key: "updated_at",
          label: this.$t("table.updatedAt"),
          sortable: true,
          type: "datetime"
        },
        {
          key: "created_at",
          label: this.$t("table.createdAt"),
          sortable: true,
          type: "datetime"
        }
      ],
      meta: {}
    };
  },
  computed: {
    ...mapGetters("route", ["params"]),
    ...mapGetters([
      "selectedProject",
      "selectedPresetVersion",
      "isDevPresetVersion"
    ]),
    filterable() {
      return this.tempFilterable;
    },
    headerButtons: function () {
      if (!this.isDevPresetVersion) {
        return [];
      }
      return [
        {
          type: "button",
          title: this.$t("queues.queueCreate"),
          icon: "fa-light fa-plus",
          emit: "create-queue"
        }
      ];
    },
    actions: function () {
      const actions = [
        {
          key: "edit",
          icon: "fal fa-pen",
          emit: "edit",
          tooltip: this.$t("general.edit")
        }
      ];
      if (this.isDevPresetVersion) {
        actions.push({
          key: "delete",
          icon: "fal fa-trash",
          emit: "delete",
          tooltip: this.$t("general.delete")
        });
      }
      return actions;
    }
  },
  watch: {
    selectedProject: function () {
      this.loadQueues();
    },
    selectedPresetVersion: function () {
      this.loadQueues();
    }
  },
  mounted() {
    this.loadQueues();
  },
  methods: {
    loadQueues(data) {
      addEventToLoadingQueue({ key: "loadSettingsQueues" });

      let params = this.params();
      const { filter } = useCurrentProjectFilter();

      Queues.getAll(data ?? params, filter.value)
        .then(response => {
          this.queues = response.data;
          this.meta = response.meta;
          this.tempFilterable = response.meta.filterable;
          removeEventFromLoadingQueue({ key: "loadSettingsQueues" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    showDetails(item) {
      this.$router.push({
        name: "projectSettingsQueuesEdit",
        params: { queueId: item.item.id }
      });
    },
    deleteQueue(item) {
      Swal.fire({
        title: this.$t("queues.deleteConfirm", { queue: item.item.raw_name }),
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: this.$t("general.remove"),
        confirmButtonColor: "var(--primary)",
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: this.$t("general.cancel")
      }).then(result => {
        if (result.isConfirmed) {
          addEventToLoadingQueue({ key: "deleteQueue" });
          Queues.delete(item.item.id)
            .then(() => {
              removeEventFromLoadingQueue({
                key: "deleteQueue",
                type: "success",
                prefix: "queues",
                name: "deleteSuccessText",
                data: { queue: item.item.raw_name }
              });
              this.loadQueues();
            })
            .catch(error => {
              this.$error(error);
            });
        }
      });
    }
  }
};
</script>
