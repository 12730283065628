<template>
  <div>
    <div>
      <Header
        :title="$t('workflowToken.title').toString()"
        :items="headerButtons"
        @create-token="
          $router.push({ name: 'projectSettingsWorkflowTokenCreate' })
        "
      />
      <TableHelper
        ref="table"
        :items="token"
        :meta="meta"
        :fields="fields"
        :actions="actions"
        :enable-filter="false"
        loading-key="loadSettingsToken"
        @reload-data="loadToken"
        @edit="showDetails"
        @delete="deleteToken"
      />
    </div>
  </div>
</template>

<script>
import FlowTokens from "@/components/Settings/FlowTokens/flowTokens";
import { mapGetters } from "vuex";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";

export default {
  components: { Header, TableHelper },
  data() {
    return {
      token: [],
      fields: [
        {
          key: "label",
          label: this.$t("table.name")
        },
        {
          key: "value",
          label: this.$t("table.token")
        },
        {
          key: "processes",
          label: this.$t("table.workflows")
        }
      ],
      searchTitle: "",
      // Meta info
      meta: {}
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams"]),
    ...mapGetters(["selectedPresetVersion", "isDevPresetVersion"]),
    headerButtons: function () {
      if (!this.isDevPresetVersion) {
        return [];
      }
      return [
        {
          type: "button",
          title: this.$t("workflowToken.create"),
          icon: "fa-light fa-plus",
          emit: "create-token"
        }
      ];
    },
    actions: function () {
      const actions = [
        {
          key: "edit",
          icon: "fal fa-pen",
          emit: "edit",
          tooltip: this.$t("general.edit")
        }
      ];
      if (this.isDevPresetVersion) {
        actions.push({
          key: "delete",
          icon: "fal fa-trash",
          emit: "delete",
          tooltip: this.$t("general.delete")
        });
      }
      return actions;
    }
  },
  watch: {
    selectedPresetVersion: function () {
      this.loadToken();
    }
  },
  mounted() {
    this.loadToken();
  },
  methods: {
    loadToken(payload = {}) {
      addEventToLoadingQueue({ key: "loadSettingsToken" });

      let params = { ...this.requestParams(), ...payload };

      FlowTokens.getAll(params)
        .then(response => {
          this.token = response.data;
          this.token.forEach(token => {
            token.processes = this.getProcessNames(token);
          });
          this.meta = response.meta;
          removeEventFromLoadingQueue({ key: "loadSettingsToken" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    getProcessNames(processToken) {
      let processNames = processToken.processes.map(p => p.name);
      return processNames.join(", ");
    },
    showDetails(item) {
      this.$router.push({
        name: "projectSettingsWorkflowTokenDetail",
        params: { tokenId: item.item.id }
      });
    },
    deleteToken(item) {
      this.$swal
        .fire({
          title: this.$t("workflowToken.remove", { name: item.item.label }),
          icon: "warning",
          showConfirmButton: true,
          confirmButtonText: this.$t("general.delete"),
          confirmButtonColor: "var(--primary)",
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("general.cancel")
        })
        .then(swalResponse => {
          if (swalResponse.isConfirmed) {
            addEventToLoadingQueue({ key: "deleteToken" });
            FlowTokens.delete(item.item.id)
              .then(() => {
                removeEventFromLoadingQueue({
                  key: "deleteToken",
                  type: "success",
                  prefix: "workflowToken",
                  name: "deleted",
                  data: { name: item.item.label }
                });
                this.loadToken();
              })
              .catch(error => {
                this.$error(error);
              });
          }
        });
    }
  }
};
</script>

<style scoped></style>
