<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <QueuesTable v-if="$route.name === 'projectSettingsQueues'" />
      <QueuesCreate v-else-if="$route.name === 'projectSettingsQueuesCreate'" />
      <QueuesDetails
        v-else-if="$route.name.includes('projectSettingsQueuesEdit')"
      />
    </div>
  </div>
</template>

<script>
import QueuesTable from "@/components/Settings/Queues/Table.vue";
import QueuesCreate from "@/components/Settings/Queues/Create.vue";
import QueuesDetails from "@/components/Settings/Queues/Edit.vue";

export default {
  name: "ProjectsSettingsQueuesIndex",
  components: {
    QueuesTable,
    QueuesCreate,
    QueuesDetails
  },
  mounted() {}
};
</script>
