<template>
  <div>
    <Header
      :title="$t('config.configurations').toString()"
      :items="headerButtons"
      @create-config="$router.push({ name: 'projectSettingsConfigCreate' })"
    />
    <TableHelper
      ref="table"
      :items="configs"
      :meta="meta"
      :fields="fields"
      :actions="actions"
      :enable-filter="false"
      default-order-by="label"
      loading-key="loadSettingsConfigs"
      @reload-data="loadConfigs"
      @edit="editConfig"
      @showVersions="showVersions"
      @delete="deleteConfig"
    />
    <BackupModal
      v-model="showVersionsModal"
      :object="selectedConfigValue"
      classname="ConfigValue"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Config from "@/components/Settings/Config/config";
import { mapGetters } from "vuex";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import BackupModal from "@/components/Backup/BackupModal.vue";

export default {
  name: "ProjectsSettingsConfigTable",
  components: {
    BackupModal,
    Header,
    TableHelper
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: this.$t("config.name"),
          sortable: true,
          thStyle: { width: "200px" }
        },
        {
          key: "label",
          label: this.$t("config.label"),
          sortable: true,
          thStyle: { width: "200px" }
        },
        {
          key: "type",
          label: this.$t("config.type"),
          sortable: true,
          thStyle: { width: "100px" }
        },
        {
          key: "value",
          label: this.$t("config.value"),
          sortable: true,
          thStyle: { width: "500px" }
        },
        {
          key: "created_at",
          formatter: "formatDate",
          label: this.$t("table.createdAt"),
          sortable: true,
          thStyle: { "min-width": "180px" }
        }
      ],
      configs: [],
      searchTitle: "",
      // Meta info
      meta: {},
      selectedConfigValue: null,
      showVersionsModal: false
    };
  },
  computed: {
    ...mapGetters("route", ["params"]),
    ...mapGetters(["selectedPresetVersion", "isDevPresetVersion"]),
    headerButtons: function () {
      if (!this.isDevPresetVersion) {
        return [];
      }
      return [
        {
          type: "button",
          title: this.$t("config.configCreate"),
          icon: this.$store.getters["config/icons"].add,
          emit: "create-config"
        }
      ];
    },
    actions: function () {
      const actions = [
        {
          key: "edit",
          icon: this.$store.getters["config/icons"].edit,
          emit: "edit",
          tooltip: this.$t("general.edit")
        },
        {
          key: "versions",
          icon: this.$store.getters["config/icons"].version,
          emit: "showVersions"
        }
      ];
      if (this.isDevPresetVersion) {
        actions.push({
          key: "delete",
          icon: this.$store.getters["config/icons"].delete,
          emit: "delete",
          tooltip: this.$t("general.delete")
        });
      }
      return actions;
    }
  },
  watch: {
    selectedPresetVersion: function () {
      this.loadConfigs();
    }
  },
  mounted() {
    this.loadConfigs();
    this.$root.$on("configsTableRefresh", () => {
      this.loadConfigs();
    });
  },
  methods: {
    loadConfigs(payload = {}) {
      addEventToLoadingQueue({ key: "loadSettingsConfigs" });

      let params = { ...this.params(), ...payload };
      Config.getAll(params)
        .then(response => {
          this.configs = response.data;
          this.meta = response.meta;
          removeEventFromLoadingQueue({ key: "loadSettingsConfigs" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    editConfig(item) {
      this.$router.push({
        name: "projectSettingsConfigDetails",
        params: { configId: item.item.id }
      });
    },
    showVersions(data) {
      this.selectedConfigValue = data.item;
      this.showVersionsModal = true;
    },
    deleteConfig(item) {
      Swal.fire({
        title: this.$t("config.configDeleteTitle"),
        text: this.$t("config.configDeleteMessage", { config: item.item.name }),
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: this.$t("general.delete"),
        confirmButtonColor: "var(--primary)",
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: this.$t("general.cancel")
      }).then(result => {
        if (result.isConfirmed) {
          addEventToLoadingQueue({ key: "deleteConfig" });

          Config.delete(item.item.id)
            .then(() => {
              removeEventFromLoadingQueue({
                key: "deleteConfig",
                type: "success",
                prefix: "config",
                name: "configDeleted"
              });

              this.loadConfigs();
            })
            .catch(error => {
              this.$error(error);
            });
        }
      });
    }
  }
};
</script>
