<template>
  <div>
    <Header
      :title="
        edit
          ? $t('workflowToken.details').toString()
          : $t('workflowToken.createTitle').toString()
      "
      :show-back-button="true"
      :items="headerButtons"
      @back="$router.push({ name: 'projectSettingsWorkflowToken' })"
      @save="saveToken"
    />
    <div class="card card-custom">
      <div class="card-body">
        <FormHelper
          ref="form"
          v-model="token"
          :form="form"
          :config="formConfig"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FlowTokens from "@/components/Settings/FlowTokens/flowTokens";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { mapGetters } from "vuex";

export default {
  components: {
    Header,
    FormHelper
  },
  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    showCancelButton: {
      type: Boolean,
      default: true
    },
    returnToRoute: {
      type: Boolean,
      default: true
    }
  },
  emits: ["token-saved"],
  data() {
    return {
      formConfig: {
        snippetPrefix: "token",
        enableVariables: false
      },
      form: [
        {
          label: this.$t("workflowToken.name"),
          name: "label",
          type: "text",
          disableReturnType: true,
          default: "",
          validations: {
            required: true,
            minLength: 3
          }
        },
        {
          label: this.$t("workflowToken.token"),
          name: "value",
          type: "text",
          disableReturnType: true,
          default: "",
          copy: true,
          validations: {
            required: true,
            minLength: 3
          }
        },
        {
          label: this.$t("workflowToken.whitelistingActive"),
          name: "ip_whitelist_active",
          type: "checkbox",
          default: false
        },
        {
          label: this.$t("workflowToken.whitelistIps"),
          name: "ip_whitelist",
          type: "array",
          dependsOn: [
            {
              name: "ip_whitelist_active",
              values: [true]
            }
          ]
        }
      ],
      edit: false,
      token: {
        label: "",
        value: "",
        ip_whitelist: [],
        ip_whitelist_active: true
      },
      ip: "",
      ipValid: true
    };
  },
  computed: {
    ...mapGetters(["selectedPresetVersion", "isDevPresetVersion"]),
    headerButtons: function () {
      if (!this.isDevPresetVersion) {
        return [];
      }
      return [
        {
          type: "button",
          title: this.$t("general.save"),
          emit: "save",
          disabledWhenLoading: true
        }
      ];
    }
  },
  watch: {
    selectedPresetVersion: function () {
      if (this.edit) {
        this.loadToken();
      }
    }
  },
  mounted() {
    const id = this.$route.params.tokenId;
    this.edit = id !== undefined;

    if (this.edit) {
      this.loadToken();
    } else {
      this.generateToken();
    }
  },
  methods: {
    loadToken() {
      const id = this.$route.params.tokenId;

      addEventToLoadingQueue({ key: "loadToken" });

      FlowTokens.get(id)
        .then(response => {
          this.token = response.data;
          removeEventFromLoadingQueue({ key: "loadToken" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    generateToken() {
      this.token.value = this.random() + this.random();
    },
    random() {
      return Math.random().toString(36).substr(2);
    },
    saveToken() {
      if (!this.edit) {
        this.storeToken();
        return;
      }
      this.updateToken();
    },
    storeToken() {
      addEventToLoadingQueue({ key: "storeToken" });

      FlowTokens.store(this.token)
        .then(response => {
          this.token = response.data;
          removeEventFromLoadingQueue({
            key: "storeToken",
            type: "success",
            prefix: "workflowToken",
            name: "saved",
            data: { name: this.token.label }
          });

          const deepCopy = JSON.parse(JSON.stringify(this.token));
          this.$emit("token-saved", deepCopy);

          this.token = {
            label: "",
            value: "",
            ip_whitelist: []
          };

          this.$router.push({ name: "projectSettingsWorkflowToken" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    updateToken() {
      addEventToLoadingQueue({ key: "updateToken" });

      const id = this.$route.params.tokenId;
      FlowTokens.update(id, this.token)
        .then(() => {
          removeEventFromLoadingQueue({
            key: "updateToken",
            type: "success",
            prefix: "workflowToken",
            name: "saved",
            data: { name: this.token.label }
          });

          this.$emit("token-saved", this.token);
        })
        .catch(error => {
          this.$error(error);
        });
    }
  }
};
</script>
