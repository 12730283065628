<template>
  <div>
    <!--begin::Configs-->
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <ConfigsTable
          v-if="$route.name === 'projectSettingsConfig'"
          @create-config="createConfig"
        />
        <ConfigCreate
          v-if="$route.name === 'projectSettingsConfigCreate'"
          :copy-from="copyFrom"
        />
        <ConfigEdit v-if="$route.name === 'projectSettingsConfigDetails'" />
      </div>
    </div>
    <!--end::Configs-->
  </div>
</template>

<script>
import ConfigsTable from "@/components/Settings/Config/Table.vue";
import ConfigCreate from "@/components/Settings/Config/Create.vue";
import ConfigEdit from "@/components/Settings/Config/Edit.vue";

export default {
  name: "ProjectsSettingsConfigIndex",
  components: {
    ConfigsTable,
    ConfigCreate,
    ConfigEdit
  },
  data() {
    return {
      copyFrom: null
    };
  },
  mounted() {},
  methods: {
    createConfig(copyFrom) {
      this.copyFrom = copyFrom;
      this.$router.push({ name: "projectSettingsConfigCreate" });
    }
  }
};
</script>
