<template>
  <div>
    <Header
      :title="$t('projects.editProject').toString()"
      :show-back-button="true"
      :items="headerButtons"
      @back="$router.push({ name: 'projectSettingsProjects' })"
      @save="updateProject"
    />
    <div class="card card-custom">
      <div class="card-body">
        <FormHelper
          ref="form"
          v-model="project"
          :form="form"
          :config="config"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Projects from "@/components/Settings/Projects/project";
import { LOAD_PROJECTS } from "@/core/services/store/switchArea.module";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { mapGetters } from "vuex";

export default {
  name: "ProjectsSettingsProjectsDetail",
  components: { Header, FormHelper },
  data() {
    return {
      config: {
        snippetPrefix: "project",
        enableVariables: false
      },
      form: [
        {
          label: this.$t("projects.name"),
          name: "name",
          type: "text",
          disableReturnType: true,
          default: "",
          validations: {
            required: true,
            minLength: 3
          }
        },
        {
          label: this.$t("general.active"),
          name: "active",
          type: "checkbox"
        },
        {
          label: this.$t("projects.description"),
          name: "description",
          type: "textarea",
          default: "",
          validations: {
            required: false
          }
        }
      ],
      project: {}
    };
  },
  computed: {
    ...mapGetters(["selectedPresetVersion", "isDevPresetVersion"]),
    headerButtons: function () {
      if (!this.isDevPresetVersion) {
        return [];
      }
      return [
        {
          type: "button",
          title: this.$t("general.save"),
          emit: "save",
          disabledWhenLoading: true
        }
      ];
    }
  },
  watch: {
    selectedPresetVersion: function () {
      this.loadProject();
    }
  },
  mounted() {
    this.loadProject();
  },
  methods: {
    loadProject() {
      addEventToLoadingQueue({ key: "loadProject" });
      let projectId = this.$route.params.projectId;
      Projects.get(projectId)
        .then(response => {
          this.project = response.data;

          removeEventFromLoadingQueue({ key: "loadProject" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    updateProject() {
      addEventToLoadingQueue({ key: "updateProject" });
      let project = this.project;

      Projects.update(project.id, project)
        .then(response => {
          this.project = response.data;
          removeEventFromLoadingQueue({
            key: "updateProject",
            type: "success",
            prefix: "projects",
            name: "saved",
            data: { name: project.name }
          });

          this.$store.dispatch(LOAD_PROJECTS);
        })
        .catch(error => {
          this.$error(error);
        });
    }
  }
};
</script>
