<template>
  <div>
    <Header
      :title="$t('queues.queueCreate').toString()"
      :show-back-button="true"
      :items="headerButtons"
      @back="$router.push({ name: 'projectSettingsQueues' })"
      @save="createQueue"
    />
    <div class="card card-custom">
      <div class="card-body">
        <FormHelper ref="form" v-model="queue" :form="form" :config="config" />
      </div>
    </div>
  </div>
</template>

<script>
import Queues from "@/components/Settings/Queues/queues";
import { bus } from "@/main";
import { mapGetters } from "vuex";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";

export default {
  name: "ProjectsSettingsQueuesCreate",
  components: { Header, FormHelper },
  data() {
    return {
      headerButtons: [
        {
          type: "button",
          title: this.$t("general.save"),
          emit: "save"
        }
      ],
      config: {
        snippetPrefix: "project",
        enableVariables: false
      },
      form: [
        {
          label: this.$t("queues.name"),
          name: "name",
          type: "text",
          disableReturnType: true,
          default: "",
          validations: {
            required: true,
            minLength: 3
          }
        },
        {
          label: this.$t("workflowDesigner.projectChoose"),
          name: "projects",
          type: "multiselect",
          clearable: true,
          options: [],
          validations: {
            required: true
          }
        },
        {
          label: this.$t("queues.maxParallelJobs"),
          name: "max_parallel_jobs",
          type: "select",
          options: [],
          validations: {
            required: true
          }
        }
      ],
      queue: {
        name: "",
        active: true,
        tries: 1,
        timeout: 1200,
        memory: 256,
        max_parallel_jobs: 2,
        projects: []
      }
    };
  },
  computed: {
    ...mapGetters(["selectedProject", "projects"])
  },
  watch: {
    projects: function () {
      this.getProjectOptions();
    }
  },
  mounted() {
    this.queue.projects = [this.selectedProject.id];
    this.getProjectOptions();

    const formJobs = Object.values(this.form).find(
      element => element.name === "max_parallel_jobs"
    );
    formJobs.options = this.setMaxParallelJobs();
  },
  methods: {
    createQueue() {
      addEventToLoadingQueue({ key: "createQueue" });
      let queue = this.queue;
      Queues.store(queue)
        .then(response => {
          this.queue = response.data;
          removeEventFromLoadingQueue({
            key: "createQueue",
            type: "success",
            prefix: "queues",
            name: "queueUpdated",
            data: { name: this.queue.name }
          });
          this.$router.push({
            name: "projectSettingsQueuesEdit",
            params: { queueId: this.queue.id }
          });
          bus.$emit("reloadHeaderFilter");
        })
        .catch(error => {
          this.$error(error);
        });
    },
    getProjectOptions() {
      // Fills multiselect projects
      const formProjects = Object.values(this.form).find(
        element => element.name === "projects"
      );
      formProjects.options = this.projects?.length
        ? this.projects.map(project => {
            return {
              label: project.name,
              value: project.id
            };
          })
        : [];
    },
    setMaxParallelJobs() {
      let maxParallelJobs = [];
      for (let i = 0; i <= 12; i++) {
        let tmpObj = {
          label: i,
          value: i
        };
        maxParallelJobs.push(tmpObj);
      }
      return maxParallelJobs;
    }
  }
};
</script>
