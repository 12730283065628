<template>
  <div>
    <Header
      :title="$t('settings.loginSecurity').toString()"
      :items="headerButtons"
      @save="save"
    />
    <div class="card card-custom">
      <div class="card-body">
        <FormHelper
          ref="form"
          v-model="securitySettings"
          :form="form"
          :config="formConfig"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Settings from "@/components/Settings/settings";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { mapGetters } from "vuex";

export default {
  name: "AdminsClientsDetailsLoginSecurity",
  components: { Header, FormHelper },
  props: {
    routeToTable: {
      type: String,
      default: "adminTenants"
    }
  },
  data() {
    return {
      formConfig: {
        snippetPrefix: "loginSecurity",
        enableVariables: false
      },
      form: [
        {
          label: this.$t("settings.securityMaxAttempts"),
          name: "maxAttempts",
          type: "number",
          disableReturnType: true
        },
        {
          label: this.$t("settings.securityLockMinutesAfterMaxAttempts"),
          name: "lockMinutesAfterMaxAttempts",
          type: "number",
          disableReturnType: true
        },
        {
          label: this.$t("settings.securityMaxAttemptsBeforeLock"),
          name: "securityMaxAttemptsBeforeLock",
          type: "number",
          disableReturnType: true
        },
        {
          label: this.$t("settings.securityTwoFaCheck"),
          name: "twoFaCheck",
          type: "checkbox"
        },
        {
          label: this.$t("settings.securityTwoFaCodeExpireAfterMinutes"),
          name: "twoFaCodeExpireAfterMinutes",
          type: "text",
          disableReturnType: true,
          dependsOn: [
            {
              name: "twoFaCheck",
              values: [true]
            }
          ]
        },
        {
          label: this.$t("settings.twoFaDeviceExpireAfterDays"),
          name: "twoFaDeviceExpireAfterDays",
          type: "text",
          disableReturnType: true,
          dependsOn: [
            {
              name: "twoFaCheck",
              values: [true]
            }
          ]
        }
      ],
      securitySettings: {
        maxAttempts: null,
        maxAttemptsBeforeLock: null,
        lockMinutesAfterMaxAttempts: null,
        twoFaCheck: false,
        twoFaCodeExpireAfterMinutes: 10,
        twoFaDeviceExpireAfterDays: 30,
        checkIP: false
      },
      settings: {}
    };
  },
  computed: {
    ...mapGetters(["selectedPresetVersion", "isDevPresetVersion"]),
    headerButtons: function () {
      if (!this.isDevPresetVersion) {
        return [];
      }
      return [
        {
          type: "button",
          title: this.$t("general.save"),
          emit: "save",
          disabledWhenLoading: true
        }
      ];
    }
  },
  watch: {
    selectedPresetVersion: function () {
      this.getSettings();
    }
  },
  mounted() {
    this.getSettings();
  },
  methods: {
    getSettings() {
      addEventToLoadingQueue({ key: "getSettings" });
      Settings.getByName("loginSecurity")
        .then(response => {
          this.settings = response.data;
          this.securitySettings = this.settings.value;
          removeEventFromLoadingQueue({ key: "getSettings" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    save() {
      addEventToLoadingQueue({ key: "saveSettings" });
      this.settings.value = this.securitySettings;
      Settings.update(this.settings.id, this.settings)
        .then(() => {
          removeEventFromLoadingQueue({
            key: "saveSettings",
            type: "success",
            prefix: "client",
            name: "clientUpdated"
          });

          this.getSettings();
        })
        .catch(error => {
          this.$error(error);
        });
    }
  }
};
</script>
