import ApiService from "@/core/services/api.service";
import { BaseService } from "@/components/Tools/baseService";

let url = process.env.VUE_APP_API_ADMIN;

class Queues extends BaseService {
  get(id) {
    return ApiService.get(url, "queues/" + id);
  }

  getAll(params, filter = []) {
    let optionalHeaders = {};
    let filterParams = this.getParams(filter, params);
    let options = new URLSearchParams(params).toString();
    return ApiService.get(
      url,
      "queues?" + options + filterParams,
      optionalHeaders
    );
  }

  store(data) {
    return ApiService.post(url + "/queues", data);
  }

  update(queueId, data, optionalHeaders) {
    return ApiService.put(url + "/queues/" + queueId, data, optionalHeaders);
  }

  delete(id) {
    return ApiService.delete(url + "/queues/" + id);
  }
}

export default new Queues();
